import React from 'react';
import dynamic from 'next/dynamic';
import Icon from '../Icon/Icon';

const BecomeCustomer = dynamic(() => import('./Icons/IconBecomeCustomer'));
const CustomerService = dynamic(() => import('./Icons/IconCustomerService'));
const ElectricityContract = dynamic(() =>
    import('./Icons/IconElectricityContract')
);
const FjarrVarme = dynamic(() => import('./Icons/IconFjarrVarme'));
const Invoice = dynamic(() => import('./Icons/IconInvoice'));
const News = dynamic(() => import('./Icons/IconNews'));
const Outage = dynamic(() => import('./Icons/IconOutage'));
const PowerFail = dynamic(() => import('./Icons/IconPowerFail'));
const PowerGrid = dynamic(() => import('./Icons/IconPowerGrid'));
const Relocation = dynamic(() => import('./Icons/IconRelocation'));
const SolarPanel = dynamic(() => import('./Icons/IconSolarPanel'));
const TerracedHouse = dynamic(() => import('./Icons/IconTerracedHouse'));
const Vacancies = dynamic(() => import('./Icons/IconVacancies'));
const WaterPower = dynamic(() => import('./Icons/IconWaterPower'));
const WindPower = dynamic(() => import('./Icons/IconWindPower'));

export const allIcons = {
    becomeCustomer: BecomeCustomer,
    customerService: CustomerService,
    electricityContract: ElectricityContract,
    fjarrVarme: FjarrVarme,
    invoice: Invoice,
    news: News,
    outage: Outage,
    powerFail: PowerFail,
    powerGrid: PowerGrid,
    relocation: Relocation,
    solarPanel: SolarPanel,
    terracedHouse: TerracedHouse,
    vacancies: Vacancies,
    waterPower: WaterPower,
    windPower: WindPower,
};

const StaticIcon = ({ type }) => {
    return <Icon type={type} />;
};

const IconAnimated = ({ icon = '', shouldStart = false, id = '' }) => {
    const Icon = allIcons[icon] || StaticIcon;
    return <Icon id={id} type={icon} shouldStart={shouldStart} />;
};

IconAnimated.propTypes = {};

export default IconAnimated;
