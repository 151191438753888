import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';

import Icon from '../Icon';
import IconAnimated from '../IconAnimated/IconAnimated';
import styles from './CardWithAnimatedIcon.module.scss';

const CardWithAnimatedIcon = ({
    icon = '',
    secondaryIcon = '',
    text = '',
    align = '',
    index = null,
    link = {},
}) => {
    const [shouldStart, setStart] = useState(false);

    if (!(link && link.href)) {
        return null;
    }

    const handleClick = (title) => {
        window._mtm = window._mtm || [];
        window._mtm.push({
            ctaButtonName: title,
            event: 'interactionsCallToActionButton',
        });
    };

    const classes = classNames(
        styles['CardWithAnimatedIcon'],
        styles['CardWithAnimatedIcon--' + ucFirst(align)]
    );

    return (
        <a
            href={link.href}
            className={classes}
            target={link.target}
            onClick={() => handleClick(link.title)}
            onMouseEnter={() => setStart(true)}
            onMouseLeave={() => setStart(false)}>
            <div className={styles['CardWithAnimatedIcon__AnimatedIcon']}>
                {!!icon && (
                    <IconAnimated
                        {...{ icon }}
                        id={`CardIcon-${index}-`}
                        shouldStart={shouldStart}
                    />
                )}
            </div>
            <div className={styles['CardWithAnimatedIcon__Content']}>
                <span className={styles['CardWithAnimatedIcon__Headline']}>
                    <span className={styles['CardWithAnimatedIcon__Title']}>
                        {link.title}
                    </span>
                    {!!secondaryIcon && (
                        <Icon type={secondaryIcon} size={'small'} />
                    )}
                </span>

                {text && (
                    <p className={styles['CardWithAnimatedIcon__SubTitle']}>
                        {text}
                    </p>
                )}
            </div>
        </a>
    );
};

CardWithAnimatedIcon.propTypes = {
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    icon: PropTypes.string,
    text: PropTypes.string,
    align: PropTypes.string,
    index: PropTypes.number,
};

export default CardWithAnimatedIcon;
