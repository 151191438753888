import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import CardWithAnimatedIcon from '../CardWithAnimatedIcon';
import styles from './MyPagesLoginChoices.module.scss';

const MyPagesLoginChoices = ({ items = [] }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['MyPagesLoginChoices']}>
            <div className={styles['MyPagesLoginChoices__Container']}>
                <h1 className={styles['MyPagesLoginChoices__Title']}>
                    {t('MyPagesLoginChoices.login')}
                </h1>
                <div className={styles['MyPagesLoginChoices__Grid']}>
                    {items.map((item, index) => (
                        <CardWithAnimatedIcon key={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

MyPagesLoginChoices.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                title: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            icon: PropTypes.string,
            secondaryIcon: PropTypes.string,
            text: PropTypes.string,
        })
    ),
};

export default MyPagesLoginChoices;
